@media only screen and (min-width: 320px) {
  nav {
    position: fixed;
    width: 100vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    background: transparent;
    color: white;
    z-index: 999;
  }

  .name {
    text-shadow: 4px 4px 6px #000;
    background: transparent;
    backdrop-filter: blur(10px);
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    padding-top: 0.6rem;
    font-size: 1.2rem;
    font-family: "Young Serif", serif;
    width: 100%;
  }

  .menu {
    text-shadow: 4px 4px 6px #000;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .menu li {
    background: transparent;
    display: inline;
    margin-right: 0.8rem;
    cursor: pointer;
  }
  .menu-link {
    background-color: transparent;
    text-decoration: none;
    cursor: pointer;
    position: relative;
  }

  .menu-link.selected:after {
    content: "";
    position: absolute;
    bottom: 50%;
    left: 50%;
    width: 100%;
    height: 2px;
    background-color: rgb(255, 255, 255);
    transform: translateX(-50%);
  }

  .menu li a {
    font-size: 1rem;
    color: white;
    font-family: "Young Serif", serif;
    text-decoration: none;
  }

  .underline {
    text-shadow: 4px 4px 6px #000;
    backdrop-filter: blur(10px);
    background: transparent;
    border-bottom: 1px solid #ffffff;
    padding-bottom: 0.7rem;
    width: 100%;
  }
}
@media only screen and (min-width: 375px) {
  .menu li {
    margin-right: 1.2rem;
  }
  .menu li a {
    font-size: 1.2rem;
  }
  .name {
    text-shadow: 4px 4px 6px #000;
    font-size: 1.4rem;
    padding-bottom: 0.5rem;
  }
}
@media only screen and (min-width: 425px) {
  .menu li a {
    font-size: 1.4rem;
  }
  .menu li {
    margin-right: 1rem;
  }
  .name {
    text-shadow: 4px 4px 6px #000;
    font-size: 1.6rem;
    padding-bottom: 0.9rem;
  }
  .menu {
    text-shadow: 4px 4px 6px #000;
  }
}
@media only screen and (min-width: 768px) {
  nav {
    padding: 0px;
    justify-content: space-around;
  }
  .name {
    text-shadow: 4px 4px 6px #000;
    width: auto;
    font-size: 1.2rem;
    padding-right: 1rem;
  }
  .menu {
    text-shadow: 4px 4px 6px #000;
    justify-content: end;
    margin-top: -2.5rem;
  }
  .menu li a {
    font-size: 1.2rem;
  }

  .underline {
    border-bottom: 2px solid #ffffff;
  }
}
@media only screen and (min-width: 1024px) {
  .name {
    font-size: 1.6rem;
    width: auto;
    padding-bottom: 0rem;
  }

  .menu li {
    padding-top: 0.5rem;
    margin-right: 1.6rem;
  }
}
@media only screen and (min-width: 1440px) {
  nav {
    justify-content: space-around;
  }
  .name {
    padding-bottom: 0rem;
    font-size: 2rem;
    width: auto;
    margin-right: 2rem;
  }
  .menu {
    justify-content: end;
    margin-top: -3rem;
  }

  .menu li {
    margin-right: 2.5rem;
  }
  .menu li a {
    font-size: 1.8rem;
  }
  .underline {
    text-shadow: 4px 4px 6px #000;
    padding-bottom: 0.5rem;
  }
}
