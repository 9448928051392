@media only screen and (min-width: 320px) {
  .college {
    padding-top: 5rem;
    display: grid;
    grid-template-columns: 1fr;
  }

  .title {
    margin-bottom: 3rem;
    display: grid;
    justify-content: center;
    color: white;
    font-family: "Young Serif", serif;
    font-size: 1.8rem;
  }

  .timeline {
    display: grid;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .timeline-item {
    display: grid;
    justify-content: center;
    border-left: 3px solid #deb887;
    padding: 20px;
    margin: 20px 2rem;
  }

  .timeline-content {
    padding: 2px;
    border-radius: 5px;
  }

  .timeline-content img {
    width: 60%;
    border-radius: 10px;
    margin-bottom: 1rem;
  }

  .timeline-content h2 {
    color: #deb887;
    font-family: "Young Serif", serif;
    font-size: 1.6rem;
  }

  .timeline-content h1 {
    color: white;
    font-family: "Bebas Neue", sans-serif;
    text-align: left;
  }

  .timeline-content p {
    color: white;
    font-family: "Poppins", sans-serif;
    font-size: 1rem;
  }
}
@media only screen and (min-width: 768px) {
  .title {
    font-size: 2.3rem;
  }

  .timeline-content img {
    width: 30%;
  }

  .timeline-content h2 {
    font-size: 2rem;
  }

  .timeline-content h1 {
    font-size: 2rem;
  }

  .timeline-content p {
    font-size: 1.4rem;
  }
}
@media only screen and (min-width: 1024px) {
  .timeline {
    display: grid;
    justify-content: center;
    align-self: start;
    grid-template-columns: 1fr 1fr;
  }

  .timeline-item {
    display: grid;
    justify-content: center;
    align-self: start;
    margin: 30px 3rem;
  }

  .timeline-content img {
    width: 40%;
  }

  .timeline-content h2 {
    font-size: 2rem;
  }

  .timeline-content h1 {
    font-size: 2rem;
  }

  .timeline-content p {
    font-size: 1.2rem;
  }
}
@media only screen and (min-width: 1440px) {
  .title {
    font-size: 2.5rem;
  }
  .timeline-item {
    margin: 30px 5rem;
  }

  .timeline-content img {
    width: 40%;
  }

  .timeline-content p {
    font-size: 1.4rem;
  }
}
@media only screen and (min-width: 1900px) {
  .title {
    font-size: 2.6rem;
  }
  .timeline-item {
    margin: 30px 5rem;
  }

  .timeline-content h1 {
    font-size: 2.6rem;
  }

  .timeline-content h2 {
    font-size: 2.6rem;
  }

  .timeline-content img {
    width: 30%;
  }

  .timeline-content p {
    font-size: 1.6rem;
  }
}
