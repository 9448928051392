@media only screen and (min-width: 320px) {
  .about {
    background-color: #131313;
    width: 100%;
    display: grid;
    justify-content: center;
  }

  .hi {
    font-family: "Young Serif", serif;
    color: white;
    font-size: 2rem;
    padding-left: 1rem;
  }

  .hi span {
    justify-content: left;
    font-size: 2rem;
    font-family: "Young Serif", serif;
    color: #deb887;
  }

  .text1 {
    color: white;
    font-family: "Poppins", sans-serif;
    font-size: 1rem;
    text-align: start;
    padding: 1rem 1rem;
  }

  .tech {
    justify-content: center;
    display: grid;
  }

  .tech img {
    margin: auto;
    width: 70%;
    margin-top: 4rem;
    animation: moveUpDown 4s infinite;
  }

  @keyframes moveUpDown {
    0% {
      transform: translateY(0); /* Comece no ponto inicial */
    }
    50% {
      transform: translateY(-40px); /* Mova para cima */
    }
    100% {
      transform: translateY(0); /* Volte para o ponto inicial */
    }
  }

  .contact {
    color: white;
    font-size: 1.2rem;
    font-weight: 700;
    font-family: "Poppins", sans-serif;
    padding-left: 1rem;
    text-decoration: underline;
  }

  .icons {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
  }

  .icons a i {
    margin-left: 1rem;
    font-size: 1.8rem;
    color: white;
    transition: color 0.3s, transform 0.3s;
  }
  .icons a i:hover {
    color: burlywood;
    transform: scale(1.2);
  }

  .linebottom {
    width: 80%;
    border-bottom: 1px solid #ffffff;
    padding-bottom: 2rem;
    margin: 0 auto;
  }
}
@media only screen and (min-width: 375px) {
  .hi {
    font-size: 2.2rem;
  }

  .text1 {
    font-size: 1.4rem;
  }
  .loader {
    width: 16em;
    height: 16em;
  }

  .contact {
    font-size: 1.4rem;
  }

  .icons a i {
    font-size: 2.2rem;
    margin-left: 1.6rem;
  }
}
@media only screen and (min-width: 425px) {
  .hi {
    font-size: 2.2rem;
    padding-left: 2rem;
  }

  .text1 {
    font-size: 1.4rem;
    padding: 1.5rem 1.5rem;
  }

  .contact {
    padding-left: 2rem;
  }

  .tech {
    padding-top: 2rem;
  }
}
@media only screen and (min-width: 768px) {
  .hi {
    margin-top: 3rem;
  }
  .contact {
    padding-left: 5rem;
  }
}
@media only screen and (min-width: 1024px) {
  .about {
    grid-template-columns: 1fr 1fr;
  }

  .hi {
    font-size: 3rem;
    padding-left: 5rem;
    grid-column: 1;
  }

  .hi span {
    font-size: 3rem;
  }
  .text1 {
    grid-column: 1;
    font-size: 1.5rem;
    text-align: left;
    padding-left: 5rem;
  }

  .tech {
    margin-top: -5rem;
    grid-column: 2;
  }

  .contact {
    padding-left: 5rem;
  }

  .icons {
    grid-column: 1;
    margin-top: 2rem;
  }

  .icons a i {
    margin-left: 2rem;
    font-size: 2.3rem;
  }

  .linebottom {
    display: grid;
    grid-column: span 2;
    width: 90%;
    padding-top: 1rem;
    border-bottom: 1px solid #ffffff;
    padding-bottom: 2rem;
    margin: 0 auto;
  }
}
@media only screen and (min-width: 1440px) {
  .about {
    grid-template-columns: 1fr 1fr;
  }

  .hi {
    padding-top: 4rem;
    font-size: 3.5rem;
    padding-left: 5rem;
    grid-column: 1;
  }

  .hi span {
    font-size: 3.5rem;
  }
  .text1 {
    grid-column: 1;
    font-size: 1.8rem;
    text-align: left;
    padding-left: 5rem;
  }

  .contact {
    font-size: 1.8rem;
    padding-left: 5rem;
    margin-top: -8rem;
  }

  .icons {
    grid-column: 1;
    margin-top: -2rem;
  }

  .icons a i {
    margin-left: 2rem;
    font-size: 3rem;
  }
}
@media only screen and (min-width: 1900px) {
  .about {
    grid-template-columns: 1fr 1fr;
  }

  .hi {
    font-size: 3.8rem;
    padding-left: 8rem;
    margin-top: 5rem;
    grid-column: 1;
  }

  .hi span {
    font-size: 3.8rem;
  }
  .text1 {
    grid-column: 1;
    font-size: 2rem;
    text-align: left;
    padding-left: 8rem;
  }

  .contact {
    font-size: 2rem;
    padding-left: 10rem;
    margin-top: -15rem;
  }

  .icons {
    grid-column: 1;
    margin-top: -8rem;
  }

  .icons a i {
    margin-left: 2rem;
    font-size: 3rem;
  }
}
@media only screen and (min-width: 2560px) {
  .hi {
    font-size: 4rem;
    padding-left: 10rem;
  }

  .hi span {
    font-size: 4rem;
  }
  .text1 {
    text-align: justify;
    font-size: 2.5rem;
    padding-left: 10rem;
  }

  .contact {
    font-size: 2.5rem;
    padding-left: 10rem;
  }
}
