@media only screen and (min-width: 320px) {
  .backgroundimage {
    margin-bottom: 3rem;
  }

  .hello {
    color: white;
    padding-top: 10rem;
    padding-left: 1rem;
    font-size: 2rem;
    font-family: "Young Serif", serif;
  }

  .front {
    color: white;
    font-family: "Bebas Neue", sans-serif;
    font-size: 4rem;
    padding-left: 1rem;
  }
  .developer {
    color: white;
    font-family: "Bebas Neue", sans-serif;
    font-size: 4rem;
    padding-left: 1rem;
    margin-top: -1.2rem;
  }

  .intro {
    color: white;
    font-family: "Poppins", sans-serif;
    font-size: 1rem;
    text-align: start;
    padding: 0.5rem 0.5rem;
    margin-bottom: 2rem;
  }

  .button {
    font-family: "Poppins", sans-serif;
    text-decoration: none;
    width: fit-content;
    --bg: #ffffff;
    --hover-bg: #deb887;
    --hover-text: #000;
    color: #000000;
    font-size: 0.8rem;
    font-weight: 700;
    border: 1px solid var(--bg);
    border-radius: 4px;
    padding: 0.8em 2em;
    margin-left: 2rem;
    margin-top: 2rem;
    background: var(--bg);
    transition: 0.2s;
  }

  .button:hover {
    color: var(--hover-text);
    transform: translate(-0.25rem, -0.25rem);
    background: var(--hover-bg);
    box-shadow: 0.25rem 0.25rem var(--bg);
  }

  .button:active {
    transform: translate(0);
    box-shadow: none;
  }
}
@media only screen and (min-width: 375px) {
  .hello {
    font-size: 2.2rem;
    padding-top: 11rem;
    padding-left: 1rem;
  }
  .front {
    font-size: 4.5rem;
  }
  .developer {
    font-size: 4.5rem;
  }
  .intro {
    font-size: 1.4rem;
  }
}
@media only screen and (min-width: 425px) {
  .hello {
    font-size: 2.4rem;
    padding-left: 2rem;
  }
  .front {
    padding-left: 2rem;
    font-size: 4.8rem;
  }
  .developer {
    padding-left: 2rem;
    font-size: 4.8rem;
  }

  .intro {
    font-size: 1.4rem;
    padding: 1.5rem 1.5rem;
  }
}
@media only screen and (min-width: 768px) {
  .backgroundimage {
    background-image: url("../img/pc.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding-top: 0rem;
    height: fit-content;
    padding-bottom: 2rem;
  }
  .hello {
    text-shadow: 4px 4px 6px #000;
    background: transparent;
    font-size: 3rem;
    padding-left: 4rem;
    padding-top: 10rem;
  }
  .front {
    text-shadow: 4px 4px 6px #000;
    background: transparent;
    font-size: 6rem;
    padding-left: 4rem;
  }
  .developer {
    text-shadow: 4px 4px 6px #000;
    background: transparent;
    font-size: 6rem;
    padding-left: 4rem;
  }
  .intro {
    text-shadow: 4px 4px 6px #000;
    background: transparent;
    text-align: justify;
    font-size: 1.4rem;
    padding: 2rem 4rem;
  }

  .button {
    margin-left: 4rem;
  }
}
@media only screen and (min-width: 1024px) {
  .backgroundimage {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .hello {
    font-size: 2.5rem;
    margin-top: 10rem;
    grid-column: 1;
    grid-row: 1;
  }

  .front {
    grid-row: 1;
    grid-column: 1;
    margin-top: 24rem;
  }

  .developer {
    grid-row: 1;
    grid-column: 1;
    margin-top: 30rem;
  }
  .intro {
    grid-column: 2;
    grid-row: 1;
    font-weight: 700;
    text-align: left;
    font-size: 1.4rem;
    padding: 4rem 4rem;
    margin-top: 20rem;
  }

  .backgroundimage a {
    grid-column: 2;
    grid-row: 2;
  }

  .button {
    margin-top: -2rem;
  }
}
@media only screen and (min-width: 1440px) {
  .backgroundimage {
    height: 60rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .hello {
    font-size: 3.8rem;
    padding-left: 4rem;
    padding-top: 12rem;
  }
  .front {
    font-size: 10rem;
    padding-left: 4rem;
    padding-top: 8rem;
  }
  .developer {
    font-size: 10rem;
    padding-top: 12rem;
    padding-left: 4rem;
  }
  .intro {
    font-weight: 700;
    text-align: left;
    font-size: 2rem;
    padding-left: 4rem;
    padding-top: 16rem;
  }
}
@media only screen and (min-width: 1900px) {
  .backgroundimage {
  }

  .hello {
    padding-top: 20rem;
  }
  .front {
    padding-top: 12rem;
  }
  .developer {
    padding-top: 15rem;
  }
  .intro {
    text-align: left;
    font-size: 2.2rem;
    padding-left: 0rem;
    padding-top: 18rem;
  }

  .button {
    font-size: 1.2rem;
    margin-top: -4rem;
    margin-left: 0;
  }
}
@media only screen and (min-width: 2560px) {
  .backgroundimage {
    height: fit-content;
  }
  .hello {
    font-size: 6.5rem;
    padding-left: 6rem;
    padding-top: 20rem;
  }
  .front {
    font-size: 16rem;
    padding-left: 6rem;
    padding-top: 26rem;
  }
  .developer {
    font-size: 16rem;
    padding-top: 34rem;
    padding-left: 6rem;
  }
  .intro {
    text-align: left;
    font-size: 3rem;
    padding-left: 0rem;
    padding-top: 38rem;
  }

  .button {
    font-size: 1.2rem;
    margin-top: 0rem;
    margin-left: 0;
  }
}
