@media only screen and (min-width: 320px) {
  .work {
    width: 100%;
    display: grid;
    justify-content: center;
    padding-top: 3rem;
  }

  .worktitle {
    display: grid;
    justify-content: center;
    color: white;
    font-family: "Young Serif", serif;
    font-size: 1.8rem;
  }

  .popup .awstitle,
  .bayertitle,
  .tivittitle,
  .saudetitle {
    display: grid;
    justify-content: center;
    padding-top: 2rem;
    color: rgb(255, 255, 255);
    font-size: 1.8rem;
    font-family: "Bebas Neue", sans-serif;
    text-align: center;
  }

  .awstitle,
  span {
    display: grid;
    justify-content: center;
    color: #deb887;
    font-family: "Poppins", sans-serif;
    font-size: 1.2rem;
  }
  .aws {
    cursor: pointer;
    margin-top: 1rem;
    background-image: url("../img/aws.png");
    background-size: cover cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #232f3e;
    width: 15rem;
    height: 8rem;
    border-radius: 10px;
    filter: grayscale(100%);
    transition: transform 0.3s, filter 0.3s;
  }

  .aws:hover {
    transform: scale(0.9);
    filter: grayscale(0%);
  }

  .bayer {
    cursor: pointer;
    margin-top: 1rem;
    background-image: url("../img/bayer.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #e8f6f6;
    width: 15rem;
    height: 8rem;
    border-radius: 10px;
    filter: grayscale(100%);
    transition: transform 0.3s, filter 0.3s;
  }

  .bayer:hover {
    transform: scale(0.9);
    filter: grayscale(0%);
  }

  .tivit {
    cursor: pointer;
    margin-top: 1rem;
    background-image: url("../img/tivit.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #e8f6f6;
    width: 15rem;
    height: 8rem;
    border-radius: 10px;
    filter: grayscale(100%);
    transition: transform 0.3s, filter 0.3s;
  }

  .tivit:hover {
    transform: scale(0.9);
    filter: grayscale(0%);
  }

  .saude {
    cursor: pointer;
    margin-top: 1rem;
    background-image: url("../img/saude.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #e8f6f6;
    width: 15rem;
    height: 8rem;
    border-radius: 10px;
    filter: grayscale(100%);
    transition: transform 0.3s, filter 0.3s;
  }

  .saude:hover {
    transform: scale(0.9);
    filter: grayscale(0%);
  }
  .modal-content {
    border: 1px solid white;
    overflow-y: auto;
    width: 80%;
    max-height: 90vh;
    margin: 0 auto;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    padding: 20px;
  }

  .modal-content h2 {
    padding-bottom: 1.4rem;
    font-family: "Bebas Neue", sans-serif;
    font-size: 1.8rem;
    text-align: left;
    color: white;
  }

  .modal-content p {
    font-family: "Poppins", sans-serif;
    font-size: 1.1rem;
    text-align: left;
    color: white;
  }

  .modal-content li {
    font-family: "Poppins", sans-serif;
    font-size: 1.1rem;
    text-align: left;
    color: white;
  }

  .modal-content i {
    display: grid;
    margin-top: 2rem;
    color: white;
    font-size: 1.5rem;
    justify-content: center;
    cursor: pointer;
  }

  .modal-content span {
    font-size: 1.1rem;
    font-family: "Poppins", sans-serif;
    text-align: left;
    color: burlywood;
    font-weight: bold;
  }

  /* Define um estilo para o overlay (fundo do modal) */
  .modal-overlay {
    overflow-y: auto;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(44, 44, 44, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
}
@media only screen and (min-width: 375px) {
  .saude,
  .tivit,
  .bayer,
  .aws {
    width: 18rem;
  }
}
@media only screen and (min-width: 425px) {
  .worktitle {
    font-size: 2rem;
  }
  .saude,
  .tivit,
  .bayer,
  .aws {
    width: 20rem;
  }

  .popup .awstitle,
  .bayertitle,
  .tivittitle,
  .saudetitle {
    font-size: 2.4rem;
  }
}
@media only screen and (min-width: 768px) {
  .worktitle {
    font-size: 2.3rem;
  }
}
@media only screen and (min-width: 1024px) {
  .work {
    width: 100%;
    display: grid;
    justify-content: center;
    padding-top: 3rem;
    padding-bottom: 10rem;
  }

  .worktitle {
    font-size: 2.5rem;
    padding-top: 2rem;
    padding-bottom: 3rem;
  }

  .popup {
    width: 100%;
    display: grid;
    justify-content: center;
    grid-template-columns: 1fr 1fr;
  }

  .bayertitle {
    margin-top: 4rem;
    grid-column: 2;
    grid-row: 2;
  }
  .bayer {
    margin-top: 4rem;
    grid-column: 1;
  }

  .tivittitle {
    margin-top: 4rem;
  }
  .tivit {
    margin-top: 4rem;
  }

  .saudetitle {
    margin-top: 4rem;
    grid-column: 2;
    grid-row: 4;
  }
  .saude {
    margin-top: 4rem;
    grid-column: 1;
    grid-row: 4;
  }
}
@media only screen and (min-width: 1440px) {
  .worktitle {
    font-size: 3.5rem;
    padding-bottom: 5rem;
  }

  .popup .awstitle {
    text-align: left;
    font-size: 3rem;
    padding-left: 18rem;
    align-self: center;
  }

  .popup .awstitle span {
    font-size: 1.8rem;
  }

  .aws {
    margin-top: 0;
    align-self: center;
    width: 30rem;
    height: 12rem;
  }

  .popup .bayertitle {
    margin-top: 3rem;
    margin-right: 10rem;
    padding: 0;
    text-align: left;
    font-size: 3rem;
    align-self: center;
  }

  .popup .bayertitle span {
    font-size: 1.8rem;
  }

  .bayer {
    margin-top: 3rem;
    margin-left: 14rem;
    align-self: center;
    width: 30rem;
    height: 12rem;
  }

  .popup .tivittitle {
    margin-top: 3rem;
    margin-left: 16rem;
    padding: 0;
    text-align: left;
    font-size: 3rem;
    align-self: center;
  }

  .popup .tivittitle span {
    font-size: 1.8rem;
  }

  .tivit {
    margin-top: 3rem;
    align-self: center;
    width: 30rem;
    height: 12rem;
  }

  .popup .saudetitle {
    margin-top: 3rem;
    margin-right: 14rem;
    padding: 0;
    text-align: left;
    font-size: 3rem;
    align-self: center;
  }

  .popup .saudetitle span {
    font-size: 1.8rem;
  }

  .saude {
    margin-top: 3rem;
    align-self: center;
    width: 30rem;
    height: 12rem;
    margin-left: 14rem;
  }

  .modal-content {
    width: 70%;
  }

  .modal-content h2 {
    font-size: 2.5rem;
    text-align: center;
  }

  .modal-content p {
    font-size: 1.6rem;
    text-align: justify;
  }

  .modal-content li {
    font-size: 1.6rem;
    text-align: justify;
  }

  .modal-content i {
    font-size: 2rem;
  }

  .modal-content span {
    font-size: 1.6rem;
    text-align: justify;
  }
}
