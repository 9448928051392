@media only screen and (min-width: 320px) {
  .skillslang {
    width: 100%;
    background: white;
    padding-top: 5rem;
    padding-bottom: 5rem;
    justify-content: center;
    display: grid;
    grid-template-columns: 1fr;
  }
  .sk {
    width: auto;
    display: grid;
    justify-content: center;
    color: rgb(0, 0, 0);
    font-family: "Young Serif", serif;
    font-size: 1.8rem;
    background: white;
    padding-bottom: 2rem;
  }

  table {
    background: white;
    width: 100%;
    border-collapse: collapse;
    margin: 0 auto;
  }

  td {
    background: white;
    padding: 10px;
    text-align: center;
  }

  .skills h2 {
    background: white;
    font-size: 1.8rem;
    font-family: "Bebas Neue", sans-serif;
    margin: 0;
  }

  .skills img {
    background: transparent;
    width: 50%;
    height: auto;
    filter: grayscale(100%);
    transition: transform 0.6s, transform 0.6s, filter 0.6s;
  }

  .skills img:hover {
    background: transparent;
    transform: scale(1.8) rotate(360deg);
    filter: grayscale(0%);
  }

  .language {
    display: grid;
    background: white;
  }
  .lg {
    padding-top: 3rem;
    padding-bottom: 2rem;
    display: grid;
    justify-content: center;
    color: rgb(0, 0, 0);
    font-family: "Young Serif", serif;
    font-size: 1.8rem;
    background: white;
  }

  .lg-container {
    display: grid;
    align-items: center;
    background: white;
    grid-template-columns: 1fr 1fr;
  }

  .language span {
    background: white;
    justify-content: left;
    padding: 1rem;
    font-size: 2rem;
    font-family: "Bebas Neue", sans-serif;
    display: grid;
    color: black;
  }
  .language img {
    display: grid;
    background: white;
    margin-left: auto;
    width: 50%;
    height: auto;
  }
}
@media only screen and (min-width: 728px) {
  .sk {
    font-size: 2.3rem;
  }

  .skills h2 {
    font-size: 2rem;
  }

  .skills img {
    width: 30%;
  }
  .lg {
    font-size: 2.3rem;
  }

  .lg-container {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
@media only screen and (min-width: 1024px) {
  .sk {
    font-size: 2.3rem;
  }

  .skills {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .skills tr {
    background-color: white;
  }

  .skills h2 {
    font-size: 2rem;
  }

  .skills img {
    padding-top: 1rem;
    width: 40%;
  }
  .lg {
    font-size: 2.3rem;
  }

  .lg-container {
    padding-right: 4%;
    display: flex;
    align-items: center; /* Centraliza o conteúdo verticalmente */
    gap: 10px; /* Espaçamento entre as imagens e as palavras */
    flex-wrap: wrap;
  }

  .language img {
    height: auto;
    width: 8%;
  }
}
@media only screen and (min-width: 1440px) {
  .sk {
    font-size: 2.8rem;
    padding-bottom: 3rem;
  }

  .skills h2 {
    font-size: 2.2rem;
  }

  .lg {
    font-size: 2.8rem;
  }

  .language span {
    font-size: 2.4rem;
  }

  .language img {
    width: 8%;
  }
  .lg-container {
    padding-right: 6%;
  }
}
@media only screen and (min-width: 1900px) {
  .sk {
    font-size: 2.8rem;
    padding-bottom: 3rem;
  }

  .skills h2 {
    font-size: 2.2rem;
  }

  .skills img {
    background: transparent;
    width: 40%;
  }

  .lg {
    font-size: 2.8rem;
  }

  .language span {
    font-size: 2.4rem;
  }

  .language img {
    width: 7%;
  }
  .lg-container {
    padding-right: 6%;
  }
}
